import React, {useEffect, useState} from 'react';
import {Button, Grid} from "@mui/material";

import FormQuestions from "./FormQuestions";
import {getAnswer} from "./Questions/answerFunctions";

function FormSections({sections, setCurrentSectionIndex, currentSectionIndex}) {
    const [error, setError] = useState([]);
    const [sending, setSending] = useState(false);
    useEffect(() => {
        // console.log(sections[currentSectionIndex])
        // console.log("NEW INDEX")
        // console.log(Object.keys(sections[currentSectionIndex].actionBefore))
    }, [currentSectionIndex]);


    const checkRequiredAndBefore = () => {
        const actionBefore = sections[currentSectionIndex].actionBefore
        if((actionBefore.action.toUpperCase() === "LINK")){
            window.open(actionBefore.link, '_blank');
        }
    }
    const checkRequiredAndNext = () => {
        setSending(true)
        let newError = [...error]
        for(let q in (sections[currentSectionIndex].questions)){
            const answer = getAnswer(sections[currentSectionIndex].id, sections[currentSectionIndex].questions[q].id)
            if(sections[currentSectionIndex].questions[q].required){
                const regex = new RegExp(sections[currentSectionIndex].questions[q].data.regexCheck);
                // console.log(sections[currentSectionIndex].questions[q],answer)
                if(answer === "" || answer === "[]" || answer === '[""]' || !regex.test(answer)) {
                    if (error.findIndex((err) => err.sid === sections[currentSectionIndex].id && err.qid === sections[currentSectionIndex].questions[q].id) < 0) {
                        newError.push({
                            sid: sections[currentSectionIndex].id,
                            qid: sections[currentSectionIndex].questions[q].id
                        })
                    }
                }else{

                    newError = newError.filter((err) => err.sid !== sections[currentSectionIndex].id || err.qid !== sections[currentSectionIndex].questions[q].id)
                }
            }
        }
        if(newError.length === 0){
            const actionNext = sections[currentSectionIndex].actionNext
            if((actionNext.action.toUpperCase() === "NEXT_AND_SEND") || (actionNext.action.toUpperCase() === "NEXT_AND_SEND_AND_EMAIL")){
                let nextIndex = sections.findIndex((sec)=>sec.id === actionNext.next)
                let data = {"answers":JSON.parse(localStorage.getItem('answers'))}
                if(localStorage.getItem('fid'))
                    data['id'] = localStorage.getItem('fid')
                // console.log("URL>>", actionNext.send)
                fetch(actionNext.send, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data)
                }).then((res)=>{
                    return res

                }).then(async (res)=>{
                    const resp = await res.json();
                    localStorage.setItem('fid', resp.id);
                    localStorage.setItem('sids', localStorage.getItem('sids') ? JSON.stringify([...JSON.parse(localStorage.getItem('sids')), resp.savedS]): JSON.stringify([resp.savedS]));
                    setSending(false)
                    if(nextIndex >= 0){

                        setCurrentSectionIndex(nextIndex)
                    }else{
                        if(currentSectionIndex < sections.length -1){
                            setCurrentSectionIndex(currentSectionIndex + 1)
                        }else{
                            console.log("ERROR")
                        }
                    }
                    if(actionNext.action.toUpperCase() === "NEXT_AND_SEND_AND_EMAIL"){
                        localStorage.clear()
                    }
                })
                    .catch((error)=>{
                        console.error(error)
                        setSending(false)
                    })




            }
        }else{
            setSending(false)
        }
        if(newError.length !== error.length){
            setError([...newError])
            return
        }
    }

    return (
        <>
            <Grid container rowSpacing={"24px"} columnSpacing={"100px"} sx={{zIndex: 100}}>
                <FormQuestions questions={sections[currentSectionIndex].questions} sectionId={sections[currentSectionIndex].id} error={error}/>
            </Grid>
            <Grid container justifyContent={"space-between"}>
                <Grid item justifySelf={"flex-end"} sx={{paddingY: "32px"}}>{Object.keys(sections[currentSectionIndex].actionBefore).length > 0 ?<Button sx={{zIndex: 5}} className={"actionBeforeButton"} variant={"dasatoYellow"} disabled={sending} onClick={checkRequiredAndBefore}>{sections[currentSectionIndex].actionBefore?.text}</Button>: <></>}</Grid>
                <Grid item justifySelf={"flex-end"} sx={{paddingY: "32px"}}>{Object.keys(sections[currentSectionIndex].actionNext).length > 0 ?<Button sx={{zIndex: 5}} className={"actionNextButton"} variant={"dasatoDefault"} onClick={checkRequiredAndNext} disabled={sending}>{sections[currentSectionIndex].actionNext?.text}</Button>: <></>}</Grid>
                {error.length > 0 && <Grid item xs={12} sx={{textAlign: "right", mt: 1, mb:4}}><span style={{color: "#FD5300", textDecoration:"underline"}}>Hups, niečo si nevyplnil/a.</span></Grid>}
            </Grid>
        </>
    );
}

export default FormSections;